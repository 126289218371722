<template>
    <div class="register">
        <div class="register_left">
            <Form ref="formInline" :model="formInline" :rules="ruleInline">
                <FormItem class="register_title">
                    <span class="fs30">注册</span>
                </FormItem>
                <FormItem label="用户名" prop="name">
                    <i-input size="large" v-model="formInline.name" placeholder="请填写用户名"/>
                </FormItem>
                <FormItem label="手机号" prop="mobile">
                    <i-input size="large" v-model="formInline.mobile" placeholder="请填写手机号"/>
                </FormItem>

                <FormItem label="验证码" prop="code">
                    <i-input size="large" v-model="formInline.code" placeholder="请填写验证码">
                        <Button slot="append" @click="getCode" style="width:100px" :disabled="show">获取验证码</Button>
                    </i-input>
                </FormItem>

                <FormItem
                        label="密码"
                        prop="password">
                    <i-input
                            size="large"
                            type="password"
                            v-model="formInline.password"
                            placeholder="请填写密码"/>
                </FormItem>

                <FormItem>
                    <i-button type="primary" :loading="formInline.modal_loading" size="large"
                              @click="handleSubmit('formInline')" class="submit-button">注册
                    </i-button>
                </FormItem>
            </Form>
        </div>

        <div class="register_right">
            <!--<img src="https://source.unsplash.com/random/400x400" alt="">-->
        </div>
    </div>
</template>

<script>
    import '@/css/common.css';

    export default {
        name: "Register",
        data() {
            return {
                formInline: {
                    name: '',
                    password: '',
                    mobile: '',
                    code: '',
                    modal_loading: false,
                },
                ruleInline: {
                    name: [
                        {required: true, message: '请填写用户名', trigger: 'blur'}
                    ],
                    mobile: [
                        {required: true, message: '请填写手机号', trigger: 'blur'},
                        {Length: '11', message: '请填写正确手机号格式', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请填写密码', trigger: 'blur'},
                        {type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur'}
                    ],
                    code: [
                        {required: true, message: '请填写验证码', trigger: 'blur'}
                    ],
                },
                show: false,
            };
        },
        methods: {
            handleSubmit(name) {
                this.formInline.modal_loading = true;
                let param = {
                    name: this.formInline.name,
                    mobile: this.formInline.mobile,
                    code: this.formInline.code,
                    password: this.md5(this.formInline.password),
                };
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.$axios
                            .post('/api/user/register', param)
                            .then((response) => {
                                if (response.data.err_no == 0) {
                                    this.$Message.success('注册成功');
                                    this.$router.push({name: 'login'});
                                } else {
                                    this.$Message.error(response.data.err_msg);
                                }
                                this.formInline.modal_loading = false;
                            });
                    }
                });
            },
            getCode(event) {
                let el = event.currentTarget;
                const TIME_COUNT = 60;
                if (this.formInline.mobile) {
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.show = true;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                                el.innerHTML = this.count;
                            } else {
                                this.show = false;
                                clearInterval(this.timer);
                                this.timer = null;
                                el.innerHTML = "获取验证码";
                            }
                        }, 1000);

                        //发送短信
                        let param = {
                            mobile: this.formInline.mobile.trim(),
                        };
                        this.$axios
                            .post('/api/user/sendCodeByPhone', param)
                            .then((response) => {
                                if (response.data.err_no == 0) {
                                    this.$Message.success('验证码发送成功，请注意查收！');
                                } else {
                                    this.$Message.error(response.data.err_msg);
                                    this.show = false;
                                    clearInterval(this.timer);
                                    this.timer = null;
                                    el.innerHTML = "获取验证码";
                                }
                            });
                    }
                } else {
                    this.$Message.error('请填写手机号！');
                }
            },
        }
    };
</script>

<style scoped>
    .register_left {
        float: left;
        width: 400px;
        margin: 80px 100px;
        height: auto;
    }

    .register_right {
        float: right;
        width: 400px;
        margin: 100px 20px;
        height: 400px;
        overflow: hidden;
    }

    .submit-button {
        width: 200px;
        float: left;
    }

    .register_title {
        text-align: left;
        color: #657180;
    }

    .ivu-form .register_title .ivu-form-item-label {
        font-size: 20px !important;
    }

    .ivu-form-item {
        margin-bottom: 20px;
    }

    .ivu-radio-default {
        margin: 0 15px;
    }
</style>
